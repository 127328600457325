import {
  EventLocality,
  EventType,
  Language,
  OrganizationTier,
} from "@prisma/client";
import * as Yup from "yup";
import { Errors } from "../../api/errors";
import { IOrganizationForm } from "../../api/types/authentication";
import { IAccessMetadata } from "../../api/types/metadata";

export const toNull = (value) => (!value ? null : value);

export const NameValidator = Yup.string()
  .trim()
  .min(2, Errors.NameTooShort)
  .max(70, Errors.NameTooLong);

export const EmailValidator = Yup.string()
  .trim()
  .email(Errors.InvalidEmail)
  .max(256, Errors.InvalidEmail);

export const PhoneValidator = Yup.string()
  .trim()
  .test("regex", Errors.InvalidPhoneNumber, async (value) => {
    if (!value) {
      return true;
    }

    const countOfNumerics = (value.match(/\d/g) || []).length;
    if (countOfNumerics < 6 || countOfNumerics > 16) {
      return false;
    }

    return /^[+\-()\d\s]{0,30}$/.test(value);
  })
  .required(Errors.PhoneNumberRequired);

export const PasswordValidator = Yup.string()
  .min(8, Errors.PasswordTooShort)
  .max(256, Errors.PasswordTooLong)
  .test("passwordStrength", Errors.PasswordTooWeak, async (value) => {
    if (!value) {
      return true;
    }

    // Lazy load zxcvbn
    const { default: zxcvbn } = await import("zxcvbn");
    return zxcvbn(value).score >= 2;
  });

export const LanguageValidator = Yup.string().oneOf<Language>(
  Object.values(Language)
);

export const EventTypeValidator = Yup.string().oneOf<EventType>(
  Object.values(EventType)
);

export const EventLocalityValidator = Yup.string().oneOf<EventLocality>(
  Object.values(EventLocality)
);

export const OrganizationTierValidator = Yup.string().oneOf<OrganizationTier>(
  Object.values(OrganizationTier)
);

export const OrganizationNameValidation = NameValidator;

export const OrganizationSchema = Yup.object().shape<IOrganizationForm>({
  name: OrganizationNameValidation.required(Errors.NameRequired),
  numberOfUnits: Yup.number()
    .required(Errors.NumberOfUnitsRequired)
    .positive(Errors.InvalidNumberOfUnits)
    .integer(Errors.InvalidNumberOfUnits),
  numberOfBoardMeetings: Yup.number()
    .required(Errors.NumberOfBoardMeetingsRequired)
    .positive(Errors.InvalidNumberOfBoardMeetings)
    .integer(Errors.InvalidNumberOfBoardMeetings),
});

export const AccessMetadataSchema = Yup.object().shape<IAccessMetadata>({
  browser: Yup.string().required(Errors.UnknownError),
  os: Yup.string().required(Errors.UnknownError),
});
