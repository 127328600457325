import { EventType } from "@prisma/client";
import { add, isAfter, isSameDay, startOfDay } from "date-fns";
import { parseFromTimeZone } from "date-fns-timezone";
import { utcToZonedTime } from "date-fns-tz";
import * as Yup from "yup";
import { Errors } from "../../api/errors";
import {
  IChangeCurrentAgendaPayload,
  IEditEventAgendaPayload,
  IEventAppearancePayload,
  IEventBasicPayload,
  IEventPropertyPayload,
  IEventVoterEditPayload,
  IEventVotersEditPayload,
  IEventDuplicatePayload,
  IEventBuyProductsPayload,
  IEventAddUnitsPayload,
} from "../../api/types/event";
import {
  IEventJoinPublicly,
  IEventPreviewInvitation,
} from "../../store/modules/joinPublic/types";
import { isDateValid } from "../date";
import { LineItemSchema } from "./billing";
import {
  EmailValidator,
  EventLocalityValidator,
  EventTypeValidator,
  LanguageValidator,
  NameValidator,
} from ".";

export const nameMaxLength = 128;

export const EventBasicSchema = Yup.object().shape<IEventBasicPayload>({
  type: EventTypeValidator.required(Errors.UnknownError),
  locality: EventLocalityValidator.required(Errors.LocalityRequired),
  name: Yup.string()
    .required(Errors.EventNameRequired)
    .max(nameMaxLength, Errors.EventNameTooLong),
  description: Yup.string(),
  language: LanguageValidator.required(Errors.LanguageRequired),
  shareResultsWithVoters: Yup.boolean(),
  sharePublicly: Yup.boolean(),
  hideStatsFromParticipants: Yup.boolean(),
  timezone: Yup.string().required(Errors.TimeZoneRequired),
  start: Yup.string()
    .required(Errors.StartDateRequired)
    .test({
      message: Errors.InvalidDate,
      test: (value) => isDateValid(new Date(value)),
    })
    .when(["timezone"], (timeZone, schema) =>
      schema.test("min", Errors.InvalidStartDate, (start) => {
        return (
          isSameDay(
            parseFromTimeZone(`${start}T00:00:00`, { timeZone }),
            startOfDay(utcToZonedTime(new Date(), timeZone))
          ) ||
          isAfter(
            parseFromTimeZone(`${start}T00:00:00`, { timeZone }),
            startOfDay(utcToZonedTime(new Date(), timeZone))
          )
        );
      })
    ),
  end: Yup.string()
    .required(Errors.EndDateRequired)
    .test({
      message: Errors.InvalidDate,
      test: (value) => isDateValid(new Date(value)),
    })
    .when(["timezone"], (timeZone, schema) =>
      schema.test("min", Errors.InvalidStartDate, (start) => {
        return (
          isSameDay(
            parseFromTimeZone(`${start}T00:00:00`, { timeZone }),
            startOfDay(utcToZonedTime(new Date(), timeZone))
          ) ||
          isAfter(
            parseFromTimeZone(`${start}T00:00:00`, { timeZone }),
            startOfDay(utcToZonedTime(new Date(), timeZone))
          )
        );
      })
    ),
  startTime: Yup.string()
    .required(Errors.StartTimeRequired)
    .when(["timezone", "start"], (timeZone, start, schema) =>
      schema.test("min", Errors.InvalidStartDate, (startTime) =>
        isAfter(
          parseFromTimeZone(`${start}T${startTime}`, { timeZone }),
          new Date()
        )
      )
    ),
  endTime: Yup.string()
    .required(Errors.EndDateRequired)
    .when(
      ["timezone", "start", "end", "startTime", "type"],
      (timeZone, start, end, startTime, type, schema) => {
        const startDateTime = parseFromTimeZone(`${start}T${startTime}`, {
          timeZone,
        });

        function getEndDateTime(endTime: string) {
          const endDateTime = parseFromTimeZone(`${end}T${endTime}`, {
            timeZone,
          });

          if (type !== EventType.noVideo && endTime == "00:00") {
            return add(endDateTime, { days: 1 });
          }

          return endDateTime;
        }

        return schema
          .test("min", Errors.InvalidMinEndDate, (endTime) => {
            return (
              getEndDateTime(endTime) >= add(startDateTime, { minutes: 30 })
            );
          })
          .test(
            "max",
            Errors.InvalidMaxEndDate,
            (endTime) =>
              type === EventType.noVideo ||
              getEndDateTime(endTime) <= add(startDateTime, { hours: 6 })
          );
      }
    ),
});

export const EventBasicOngoingSchema = EventBasicSchema.shape({
  start: Yup.string()
    .required(Errors.StartDateRequired)
    .test({
      message: Errors.InvalidDate,
      test: (value) => isDateValid(new Date(value)),
    }),
  end: Yup.string()
    .required(Errors.EndDateRequired)
    .test({
      message: Errors.InvalidDate,
      test: (value) => isDateValid(new Date(value)),
    }),
  startTime: Yup.string().required(Errors.StartTimeRequired),
  endTime: Yup.string().required(Errors.EndDateRequired),
});

export const EventVoterSchema = Yup.object().shape<IEventVoterEditPayload>({
  id: Yup.string().required(Errors.UnknownError),
  name: Yup.string().when("deleted", {
    is: true,
    then: Yup.string(),
    otherwise: NameValidator.required(Errors.NameRequired),
  }),
  email: Yup.string().when("deleted", {
    is: true,
    then: Yup.string(),
    otherwise: EmailValidator.required(Errors.EmailRequired),
  }),
  weight: Yup.number().when("deleted", {
    is: true,
    then: Yup.number(),
    otherwise: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required(Errors.WeightRequired)
      .positive(Errors.InvalidWeight)
      .min(0, Errors.InvalidWeight)
      .max(1_000_000_000, Errors.InvalidWeight),
  }),
  proxy: Yup.boolean().required(Errors.UnknownError),
  suggestedRepresentative: Yup.boolean().required(Errors.UnknownError),
  removeVotingRights: Yup.boolean().required(Errors.UnknownError),
  language: LanguageValidator,
  admin: Yup.boolean().required(Errors.UnknownError),
  unit: Yup.string().nullable(),
  // Ignored
  invitationStatus: Yup.mixed(),
  type: Yup.string(),
  checkedIn: Yup.boolean().required(Errors.UnknownError),
});

export const EventVotersSchema = Yup.object().shape<IEventVotersEditPayload>({
  voters: Yup.array(EventVoterSchema)
    .min(1, Errors.EventVotersRequired)
    .test({
      test: (voters: IEventVoterEditPayload[]) => {
        // Check if any email is doubled
        const voterEmails = [];
        for (const voter of voters) {
          // Ignore deleted voters or missing emails
          if (!voter.email) {
            continue;
          }

          if (voterEmails.includes(voter.email.toLowerCase())) {
            return false;
          }

          voterEmails.push(voter.email.toLowerCase());
        }

        return true;
      },
      message: Errors.DuplicateEmail,
    }),
});
export const EventAgendaSchema = Yup.object().shape<IEditEventAgendaPayload>({
  agenda: Yup.array(Yup.string().required(Errors.AgendaItemRequired).trim()),
});

export const EventVoterEditEmailSchema = Yup.object()
  .shape({
    email: EmailValidator.required(Errors.EmailRequired),
  })
  .required(Errors.UnknownError);

export const CurrentAgendaSchema =
  Yup.object().shape<IChangeCurrentAgendaPayload>({
    current: Yup.number()
      .integer(Errors.UnknownError)
      .required(Errors.UnknownError),
  });

export const EventPreviewInvitationSchema =
  Yup.object().shape<IEventPreviewInvitation>({
    email: EmailValidator.required(Errors.EmailRequired),
  });

export const EventJoinPubliclySchema = Yup.object().shape<IEventJoinPublicly>({
  email: EmailValidator.required(Errors.EmailRequired),
  name: NameValidator.required(Errors.NameRequired),
  language: LanguageValidator.required(Errors.LanguageRequired),
});

export const EventPropertySchema = Yup.object().shape<IEventPropertyPayload>({
  propertyId: Yup.string().required(Errors.PropertyRequired),
});

export const EventAppearanceSchema =
  Yup.object().shape<IEventAppearancePayload>({
    publicShare: Yup.object({
      title: Yup.string().optional(),
      subtitle: Yup.string().optional().trim(),
    }).optional(),
  });

export const EventDuplicateSchema = Yup.object().shape<IEventDuplicatePayload>({
  documents: Yup.boolean().optional(),
  participants: Yup.boolean().optional(),
  polls: Yup.boolean().optional(),
  agenda: Yup.boolean().optional(),
});

export const EventBuyProductsSchema =
  Yup.object().shape<IEventBuyProductsPayload>({
    event: LineItemSchema.default(null).nullable(),
    addOns: Yup.array(LineItemSchema),
  });

export const AddUnitsSchema = Yup.object().shape<IEventAddUnitsPayload>({
  units: Yup.number()
    .required(Errors.NumberOfUnitsRequired)
    .positive(Errors.InvalidNumberOfUnits)
    .integer(Errors.InvalidNumberOfUnits),
  redirectUrl: Yup.string().required(),
});
