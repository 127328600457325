import { FormHelperTextProps } from "@material-ui/core";
import { InputBaseComponentProps } from "@material-ui/core/InputBase/InputBase";
import { FormikProps, FormikValues, getIn, useFormik } from "formik";
import { TFunction } from "i18next";

interface ISpreadFieldOptions {
  noHelperText?: boolean;
  noError?: boolean;
  inputProps?: InputBaseComponentProps;
  FormHelperTextProps?: Partial<FormHelperTextProps>;
  dataQa?: string;
}

export function spreadField(
  formik: ReturnType<typeof useFormik> | FormikProps<FormikValues>,
  field: string,
  t: TFunction,
  options: ISpreadFieldOptions = {}
) {
  const props: Record<string, unknown> = {
    name: field,
    onChange: formik.handleChange,
    value: getIn(formik.values, field),
    inputProps: {
      ...options.inputProps,
      "data-qa": options.dataQa ? `${options.dataQa}-input` : `${field}-input`,
    },
    FormHelperTextProps: {
      ...options.FormHelperTextProps,
      "data-qa": options.dataQa
        ? `${options.dataQa}-helper`
        : `${field}-helper`,
    },
  };

  if (!options.noHelperText) {
    props.helperText = t(getIn(formik.errors, field) as string, {
      ns: "errors",
    });
  }

  if (!options.noError) {
    props.error = !!getIn(formik.errors, field);
  }

  return props;
}
