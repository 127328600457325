import {
  format,
  isSameDay,
  intervalToDuration,
  isAfter,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { fr, enCA } from "date-fns/locale";
import { formatToTimeZone } from "date-fns-timezone";
import { utcToZonedTime } from "date-fns-tz";

export function prettyFormatEventDate(
  startDate: Date,
  endDate: Date,
  language: string,
  timezone: string
) {
  if (!isDateValid(startDate) || !isDateValid(endDate)) {
    return language === "fr" ? "Date invalide" : "Invalid date";
  }
  const dateTemplate = language === "fr" ? "d MMMM H:mm" : "MMMM do h:mm";
  const timeTemplate = language === "fr" ? "H:mm" : "h:mm a";
  const zoneTemplate = "(z)";
  const options = {
    locale: language == "en" ? undefined : fr,
  };
  const zonedStartDate = utcToZonedTime(startDate, timezone);
  const zonedEndDate = utcToZonedTime(endDate, timezone);

  const start = format(zonedStartDate, dateTemplate, options);
  const timezoneSuffix = formatToTimeZone(zonedEndDate, zoneTemplate, {
    timeZone: timezone,
  });

  if (isSameDay(startDate, endDate)) {
    const endHours = format(zonedEndDate, timeTemplate, options);
    return `${start}-${endHours} ${timezoneSuffix}`;
  }

  const end = format(zonedEndDate, dateTemplate, options);
  return `${start} - ${end} ${timezoneSuffix}`;
}

export function prettyFormatDateTime(
  date: Date,
  language: string,
  timezone: string
) {
  if (!isDateValid(date)) {
    return language === "fr" ? "Date invalide" : "Invalid date";
  }
  const template = language === "fr" ? "d MMMM H:mm" : "MMMM do h:mm";
  const zoneTemplate = "(z)";
  const options = {
    locale: language == "en" ? undefined : fr,
  };
  const zonedDate = utcToZonedTime(date, timezone);

  const formattedDate = format(zonedDate, template, options);
  const timezoneSuffix = formatToTimeZone(zonedDate, zoneTemplate, {
    timeZone: timezone,
  });

  return `${formattedDate} ${timezoneSuffix}`;
}

export function prettyFormatAbsoluteDateWithTime(date: Date) {
  return format(date, "yyyy/MM/dd k:mm");
}

export function prettyFormatAbsoluteDateWithDashes(date: Date) {
  return format(date, "yyyy-MM-dd");
}

export function prettyFormatDate(date: Date, language: string) {
  const lang = language == "en" ? enCA : fr;
  const dateTemplate = language === "fr" ? "d MMMM, yyyy" : "MMMM do, yyyy";

  return format(date, dateTemplate, { locale: lang });
}

export function prettyFormatDateTimezone(
  date: Date,
  language: string,
  timezone: string
) {
  const lang = language == "en" ? enCA : fr;
  const dateTemplate = language === "fr" ? "d MMMM, yyyy" : "MMMM do, yyyy";
  const zonedDate = utcToZonedTime(date, timezone);

  return format(zonedDate, dateTemplate, { locale: lang });
}

export function prettyFormatTime(date: Date) {
  return format(date, "k:mm");
}

export function prettyFormatEventDuration(
  start: Date,
  end: Date,
  language: string,
  allowNegative = false
) {
  const lang = language === "en" ? enCA : fr;
  const { days, hours, minutes } = intervalToDuration({ start, end });

  const isStartAfter = allowNegative && isAfter(start, end);

  let duration = lang.formatDistance(
    "xHours",
    `${hours || "0"}:${minutes ? minutes.toString().padStart(2, "0") : "00"}`,
    { addSuffix: false, zeros: true }
  );

  if (days) {
    const daysFormatted =
      language === "en"
        ? days > 1
          ? "days"
          : "day"
        : days > 1
        ? "jours"
        : "jour";

    duration = `${days} ${daysFormatted} ${duration}`;
  }

  return isStartAfter ? `-${duration}` : duration;
}

export function prettyFormatAbsoluteDuration(
  start: Date,
  end: Date,
  hoursTranslations: string,
  minutesTranslations: string
) {
  const hours = differenceInHours(new Date(end), new Date(start));
  const minutes =
    differenceInMinutes(new Date(end), new Date(start)) - hours * 60;

  return [
    hours ? `${hours} ${hoursTranslations} ` : null,
    minutes ? `${minutes} ${minutesTranslations}` : null,
  ].join("");
}

export function isDateValid(date: Date) {
  return date.getTime() === date.getTime();
}

export function formatDifferenceInHours(start: Date, end: Date) {
  const duration = differenceInMinutes(end, start);

  const hours = Math.floor(duration / 60);
  const minutes = duration - hours * 60;

  return `${hours}:${minutes.toString().padStart(2, "0")}`;
}
