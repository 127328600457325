import * as Yup from "yup";
import { Errors } from "../../api/errors";
import {
  ICheckPaymentPayload,
  ISubscribePayload,
} from "../../store/modules/billing/types";
import { ILineItem } from "../../types/billing";
import { OrganizationTierValidator } from "./index";

export const SubscribeSchema = Yup.object().shape<ISubscribePayload>({
  quantity: Yup.number()
    .required(Errors.InvalidBillingQuantity)
    .integer(Errors.InvalidBillingQuantity)
    .positive(Errors.InvalidBillingQuantity),
  plan: OrganizationTierValidator.required(Errors.TierRequired),
});

export const LineItemSchema = Yup.object().shape<ILineItem>({
  quantity: Yup.number().optional(),
  key: Yup.string().required(),
});

export const CheckPaymentStatusSchema =
  Yup.object().shape<ICheckPaymentPayload>({
    status: Yup.string().optional(),
  });
