import { useMemo } from "react";
import { useSelector } from "react-redux";
import {
  getIsLoadingHistorySelector,
  getIsLoadingSelector,
  selectAllLoading,
} from "../store/modules/api/selectors";

export function useIsLoading(key: string) {
  const selector = useMemo(() => getIsLoadingSelector(key), [key]);

  return useSelector(selector);
}

export function useIsLoadingHistory(key: string) {
  const selector = useMemo(() => getIsLoadingHistorySelector(key), [key]);

  return useSelector(selector);
}

export function useIsAnyLoading(...keys: string[]) {
  const allLoading = useSelector(selectAllLoading);

  return keys.some((key) => allLoading.includes(key));
}
